var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('Layout',[_c('h4',[_vm._v("New Interim Registration")]),_c('form',{ref:"formContainer",staticClass:"form-horizontal vld-parent",on:{"submit":function($event){$event.preventDefault();return _vm.requestInterim.apply(null, arguments)}}},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-lg-6"},[_c('div',{staticClass:"form-group"},[_c('label',{attrs:{"for":"ownerfname"}},[_vm._v("Unit")]),_c('b-form-select',{class:{
                            'is-invalid':
                                _vm.submitted && _vm.$v.interim.unit_id.$error,
                        },attrs:{"id":"ownerfname","name":"ownerfname","options":_vm.mappedUserUnits},on:{"change":function($event){return _vm.checkPayment(_vm.interim.unit_id)}},model:{value:(_vm.interim.unit_id),callback:function ($$v) {_vm.$set(_vm.interim, "unit_id", $$v)},expression:"interim.unit_id"}}),(_vm.submitted && _vm.$v.interim.unit_id.$error)?_c('div',{staticClass:"invalid-feedback"},[(!_vm.$v.interim.unit_id.required)?_c('span',[_vm._v("Unit is required")]):_vm._e()]):_vm._e()],1)]),(_vm.payment == 3)?_c('div',{staticClass:"col-lg-6"},[_c('div',{staticClass:"form-group"},[_c('label',{attrs:{"for":"ownerfname"}},[_vm._v("Bank Name")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.interim.bank_name),expression:"interim.bank_name"}],staticClass:"form-control mb-3",class:{
                            'is-invalid':
                                _vm.submitted && _vm.$v.interim.bank_name.$error,
                        },attrs:{"type":"text","id":"username"},domProps:{"value":(_vm.interim.bank_name)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.interim, "bank_name", $event.target.value)}}}),(_vm.submitted && _vm.$v.interim.bank_name.$error)?_c('div',{staticClass:"invalid-feedback"},[(!_vm.$v.interim.bank_name.required)?_c('span',[_vm._v("Bank name required")]):_vm._e()]):_vm._e()])]):_vm._e()]),(_vm.payment == 3)?_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-lg-12"},[_c('label',{attrs:{"for":"ownerfname"}},[_vm._v("Bank Offer Letter")]),_c('b-form-file',{staticClass:"mb-3",class:{
                        'is-invalid':
                            _vm.submitted && _vm.$v.interim.offer_letter.$error,
                    },attrs:{"placeholder":"Choose File","drop-placeholder":"Drop file here...","accept":"image/png, image/gif, image/jpeg,application/pdf"},on:{"change":_vm.handleOfferLetter}}),(_vm.submitted && _vm.$v.interim.offer_letter.$error)?_c('div',{staticClass:"invalid-feedback"},[(!_vm.$v.interim.offer_letter.required)?_c('span',[_vm._v("Bank offer letter is required")]):_vm._e()]):_vm._e()],1)]):_vm._e(),_c('submitButton')],1),_c('paymentModal',{ref:"paymentWindow",attrs:{"requestId":_vm.requestId,"referenceNumber":_vm.refernceNumber,"modalTitle":_vm.modalTitle,"page":"Interim Registration","adminFees":_vm.adminFees,"adminVatFees":_vm.adminVatFees,"serviceFees":_vm.serviceFees,"securityDepFees":_vm.securityDepFees,"urgentFees":_vm.urgentFees}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }