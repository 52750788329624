<template>
    <Layout>
        <h4>New Interim Registration</h4>
        <form
            class="form-horizontal vld-parent"
            @submit.prevent="requestInterim"
            ref="formContainer"
        >
            <div class="row">
                <div class="col-lg-6">
                    <div class="form-group">
                        <label for="ownerfname">Unit</label>
                        <b-form-select
                            id="ownerfname"
                            name="ownerfname"
                            v-model="interim.unit_id"
                            :options="mappedUserUnits"
                            @change="checkPayment(interim.unit_id)"
                            :class="{
                                'is-invalid':
                                    submitted && $v.interim.unit_id.$error,
                            }"
                        ></b-form-select>

                        <div
                            v-if="submitted && $v.interim.unit_id.$error"
                            class="invalid-feedback"
                        >
                            <span v-if="!$v.interim.unit_id.required"
                                >Unit is required</span
                            >
                        </div>
                    </div>
                </div>
                <div class="col-lg-6" v-if="payment == 3">
                    <div class="form-group">
                        <label for="ownerfname">Bank Name</label>
                        <input
                            v-model="interim.bank_name"
                            type="text"
                            class="form-control mb-3"
                            id="username"
                            :class="{
                                'is-invalid':
                                    submitted && $v.interim.bank_name.$error,
                            }"
                        />
                        <div
                            v-if="submitted && $v.interim.bank_name.$error"
                            class="invalid-feedback"
                        >
                            <span v-if="!$v.interim.bank_name.required"
                                >Bank name required</span
                            >
                        </div>
                    </div>
                </div>
            </div>
            <div class="row" v-if="payment == 3">
                <div class="col-lg-12">
                    <label for="ownerfname">Bank Offer Letter</label>
                    <b-form-file
                        placeholder="Choose File"
                        drop-placeholder="Drop file here..."
                        @change="handleOfferLetter"
                        class="mb-3"
                        accept="image/png, image/gif, image/jpeg,application/pdf"
                        :class="{
                            'is-invalid':
                                submitted && $v.interim.offer_letter.$error,
                        }"
                    ></b-form-file>
                    <div
                        v-if="submitted && $v.interim.offer_letter.$error"
                        class="invalid-feedback"
                    >
                        <span v-if="!$v.interim.offer_letter.required"
                            >Bank offer letter is required</span
                        >
                    </div>
                </div>
            </div>
            <submitButton />
        </form>
        <paymentModal
            :requestId="requestId"
            :referenceNumber="refernceNumber"
            ref="paymentWindow"
            :modalTitle="modalTitle"
            page="Interim Registration"
            :adminFees="adminFees"
            :adminVatFees="adminVatFees"
            :serviceFees="serviceFees"
            :securityDepFees="securityDepFees"
            :urgentFees="urgentFees"
        />
    </Layout>
</template>
<script>
import Layout from "../../layouts/main.vue";
import alertMixin from "../../../mixins/alertMixin";
import { BASE_URL, REQUEST_TYPES } from "../../common";
import axios from "axios";
import { required, requiredIf } from "vuelidate/lib/validators";
import paymentModal from "../payment/paymentModal.vue";
import submitButton from "../../../components/submitButton.vue";

export default {
    mixins: [alertMixin],
    validations: {
        interim: {
            unit_id: { required },
            offer_letter: {
                required: requiredIf(function () {
                    return this.payment == 3;
                }),
            },

            bank_name: {
                required: requiredIf(function () {
                    return this.payment == 3;
                }),
            },
        },
    },
    components: {
        Layout,
        paymentModal,
        submitButton,
    },
    mounted() {
        this.getTermsConditions();
    },
    data() {
        return {
            requestId: "",
            refernceNumber: "",
            submitted: false,
            adminFees: "",
            adminVatFees: "",
            securityDepFees: "",
            urgentFees: "",
            serviceFees: "",
            modalTitle: "",
            payment: "",
            interim: {
                unit_id: "",
                bank_name: "",
                offer_letter: "",
                offer_letter_filename: "",
            },
        };
    },
    methods: {
        requestInterim() {
            this.submitted = true;
            this.$v.$touch();
            if (!this.$v.$error) {
                console.log(this.interim);
                if (localStorage.cs_user_token) {
                    let loader = this.$loading.show({
                        container: this.fullPage
                            ? null
                            : this.$refs.formContainer,
                        canCancel: false,
                        onCancel: this.onCancel,
                    });
                    axios
                        .post(
                            `${BASE_URL}interims?token=${localStorage.cs_user_token}`,
                            this.interim
                        )
                        .then((res) => {
                            // this.resetFields();
                            console.log(res.data.response);
                            this.requestId = res.data.response.request_id;
                            this.refernceNumber = res.data.response.ref_no;
                            this.modalTitle =
                                "Payment for  " +
                                " " +
                                res.data.response.ref_no;
                            loader.hide();
                            this.$refs.paymentWindow.showModal();
                        })
                        // eslint-disable-next-line no-unused-vars
                        .catch((err) => {
                            loader.hide();
                        });
                }
            }
        },
        handleOfferLetter(e) {
            let reader = new FileReader();
            this.interim.offer_letter_filename = e.target.files[0].name;
            reader.onload = (e) => {
                this.interim.offer_letter = e.target.result.split(",")[1];
            };
            reader.readAsDataURL(e.target.files[0]);
        },
        getTermsConditions() {
            if (localStorage.cs_user_token) {
                let payload = {
                    request_type: REQUEST_TYPES.find(
                        (x) => x.type === "Interim Registration"
                    ).id,
                };
                axios
                    .post(
                        `${BASE_URL}getrequeststermsconditions?token=${localStorage.cs_user_token}`,
                        payload
                    )
                    .then((res) => {
                        this.adminFees = res.data.response.service_fees;
                        this.serviceFees = res.data.response.service_fees;
                        this.urgentFees = res.data.response.urgent_fees;
                        this.adminVatFees = res.data.response.admin_vat_fees;
                        this.securityDepFees =
                            res.data.response.security_deposit;
                    });
            }
        },
        checkPayment(id) {
            let unit = this.mappedUserUnits.filter((ele) => ele.value == id);
            // console.log(unit);
            this.payment = unit[0].payment;
            console.log(this.payment);
            // if (unit[0].payment == 1 || unit[0].payment == 2) {
            switch (this.payment) {
                case 1:
                    this.showError(
                        "Not applicable for unit under full payment plan"
                    );
                    break;
                case 2:
                    this.showError("Not applicable for unit under mortgage");
                    break;
                default:
                    return;
            }
            // this.in.unit_id = "";
            // }
        },
    },
    computed: {
        userUnits() {
            return this.$store.getters["apidata/getUserInfo"];
            // return JSON.parse(localStorage.user_info).units;
        },
        mappedUserUnits() {
            return this.userUnits?.units?.map((ele) => {
                return {
                    value: ele.unit_id,
                    text: ele.unit_no,
                    payment: ele.payment_type,
                };
            });
        },
    },
};
</script>
